<template>
	<div class="col-12 col-md-9 p-0">
		<TextPlay
			weight="semibold"
			color="var(--fontcolor)"
			:text="$t('gamification.ranking_caption_title')"
		/>
		<TextPlay
			color="var(--text-color)"
			:text="$t('gamification.keep_studying')"
			class="mb-4"
		/>
		<RankingList
			:rankingList="rankingList"
			:max="10"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RankingList from "./RankingList.vue";

export default {
	components: {
		RankingList,
	},
	methods: {
		...mapActions({ fetchRankingForStudent: "gamification/fetchRankingForStudent" }),
	},
	computed: {
		...mapGetters({ rankingList: "gamification/getRankingForStudent" }),
	},
	async mounted() {
		await this.fetchRankingForStudent();
	},
};
</script>
