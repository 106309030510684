<template>
	<div class="gamification d-flex flex-column">
		<div class="gamification__about-section p-0 col-12 col-md-10">
			<TitlePage
				class="mb-2"
				:title="$t('gamification.title')"
			>
				<IconSax name="cup" />
			</TitlePage>

			<div class="gamification__about-section__info">
				<TextPlay
					color="var(--text-color)"
					:text="$t('gamification.student_about')"
				/>
			</div>
		</div>

		<DividerPlay class="my-4" />

		<div class="gamification__tabs p-0">
			<TabsPlay
				enableAllTabs
				:tabs="[
					{ icon: 'ranking-1', label: 'Ranking' },
					{ icon: 'buy-crypto', label: 'Medalhas' },
					// { icon: 'wallet', label: 'Carteira' },
				]"
				:selectedTab="selectedTab"
				@selectTab="selectedTab = $event"
			>
				<template slot="0"><StudentRankingTab /></template>
				<template slot="1"><StudentMedalsTab /></template>
				<!-- <template slot="2"><StudentWalletTab /></template> -->
			</TabsPlay>
		</div>
	</div>
</template>

<script>
import TitlePage from "@/components/common/TitlePage.vue";
import StudentRankingTab from "@/components/StudentRankingTab.vue";
import StudentMedalsTab from "@/components/StudentMedalsTab.vue";
// import StudentWalletTab from "@/components/StudentWalletTab.vue";

export default {
	components: {
		TitlePage,
		StudentRankingTab,
		StudentMedalsTab,
		// StudentWalletTab,
	},
	data() {
		return {
			selectedTab: 0,
		};
	},
};
</script>

<style lang="scss" scoped>
.gamification {
	padding: 64px 40px;
}

@media (max-width: 768px) {
	.gamification {
		padding: 32px 20px;
	}
}
</style>
