<template>
	<div class="achievements d-flex flex-column p-0">
		<TextPlay
			class="mr-2 mb-5"
			weight="bold"
			color="var(--fontcolor)"
			:text="title"
		/>

		<div
			v-if="medalsList.length > 0"
			class="achievements__container py-0"
		>
			<Medal
				v-for="(medal, index) in processedMedalsList"
				:key="index"
				:name="medal.name"
				:picture="medal?.emblem?.picture"
				medalSize="lg"
			/>
		</div>

		<div v-else>
			<TextPlay
				color="var(--text-color)"
				:text="$t('gamification.no_medals')"
			/>
		</div>
	</div>
</template>

<script>
import Medal from "@/components/Medal.vue";

export default {
	components: {
		Medal,
	},
	props: {
		medalsList: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: "",
		},
		isTen: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		processedMedalsList() {
			let list = [...this.medalsList];

			if (this.isTen && list.length < 10) {
				const blockedMedal = {
					id: null,
					name: this.$t("gamification.locked_medal"),
					points: null,
					emblem_id: null,
					emblem: {
						id: null,
						picture: `${process.env.VUE_ASSETS_URL}/images/gamification/emblems/moeda-desabilitada.png`,
						category: null,
					},
				};

				while (list.length < 10) {
					list.push(blockedMedal);
				}
			}

			return list;
		},
	},
};
</script>

<style lang="scss">
@media (max-width: 768px) {
	.medal__name-container {
		.text-play {
			max-width: 100% !important;
			width: 100% !important;
			white-space: normal !important;
			overflow: visible !important;
			text-overflow: initial !important;
		}
	}
}
</style>

<style lang="scss" scoped>
.achievements {
	&__container {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		column-gap: 8px;
		row-gap: 56px;
	}
}

@media (max-width: 768px) {
	.achievements {
		&__container {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
</style>
