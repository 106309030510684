<template>
	<div>
		<TextPlay
			weight="semibold"
			color="var(--fontcolor)"
			:text="$t('gamification.recognition_medals')"
		/>
		<TextPlay
			color="var(--text-color)"
			class="mb-5"
			v-html="$t('gamification.about_recognition')"
		/>
		<Achievements
			:title="$t('gamification.my_achievements')"
			:medalsList="medalsList"
		/>
	</div>
</template>

<script>
import Achievements from "@/components/Achievements.vue";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		Achievements,
	},
	computed: {
		...mapGetters({ medalsList: "gamification/getMembersMedals", getMe: "auth/getMe" }),
	},
	methods: {
		...mapActions({
			fetchMembersMedals: "gamification/fetchMembersMedals",
		}),
	},
	watch: {
		"getMe.member.id": {
			async handler(newId) {
				await this.fetchMembersMedals(newId);
			},
			immediate: true,
		},
	},
};
</script>
